
import { Component, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import DialogService from "@/components/request/DialogService.vue";
import DateHelpers from "@/mixins/DateHelpers";
import UploadCV from "../forms/UploadCV.vue";

interface Tipos {
  value: number | any;
  label: string;
}

interface dataItem {
  id: number | any;
  nombre: string | any;
}

interface dataFilter {
  categorias: Array<dataItem>;
  fechas: number | any;
  search: string | any;
}

interface dataOfertas {
  codigo: string | any;
  descripcion: string | any;
  estado: number | any;
  fecha_publicacion: string | any;
  fecha_vigencia: string | any;
  id_categoria: number | any;
  id: number | any;
  nombre: string | any;
  direccion: string | any;
  empresa: string | any;
  generico: number | any;
}

@Component({
  components: {
    DialogService,
    UploadCV
  }
})
export default class SearchEmployment extends Mixins(
  FormValidator,
  DateHelpers
) {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;

  tab = "";
  last_tab = "";
  test = "test1";
  expanded = false;
  expandedFecha = false;
  ofertas: Array<any> = [];
  categorias: Array<any> = [];

  dataDialog = false;

  loading_categories = true;
  loading_offers = true;

  upload_cv_dialog = false;

  private dataBuscar: dataFilter = {
    categorias: [],
    fechas: 0,
    search: ""
  };

  private ofertasData: dataOfertas = {
    codigo: "",
    descripcion: "",
    estado: null,
    fecha_publicacion: "",
    fecha_vigencia: "",
    id_categoria: null,
    id: null,
    nombre: "",
    direccion: null,
    empresa: null,
    generico: null
  };

  fechas = [
    {
      id: 0,
      label: "Todos",
      seleccionado: false
    },
    {
      id: 1,
      label: "Día anterior",
      seleccionado: false
    },
    {
      id: 2,
      label: "Los últimos 3 días",
      seleccionado: false
    },
    {
      id: 3,
      label: "Última semana",
      seleccionado: false
    },
    {
      id: 4,
      label: "Último mes",
      seleccionado: false
    }
  ];

  private formatFecha(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  showCategorias() {
    this.tab = this.tab == "categorias_tab" ? "" : "categorias_tab";
  }

  showFechas() {
    this.tab = this.tab == "fechas_tab" ? "" : "fechas_tab";
  }

  showIconFecha() {
    return this.tab == "fechas_tab" ? "expand_less" : "expand_more";
  }

  options_services: any[] = [];
  service_selected: any = "";
  motive_selected: any = {};
  category_selected: any = {};
  filter_string: string = "";
  servicio: boolean = false;
  servicios: any;
  serviciosCat: Array<any> = [];
  servicio_tag: Array<any> = [];
  private catName = "";
  private aServicesCat: Array<any> = [];
  private servicesOptions: Array<any> = [];
  private text = "";
  private url: any;
  private winSize: boolean = false;
  private window = {
    width: 0,
    height: 0
  };

  private catService: Tipos = {
    value: null,
    label: ""
  };

  @Watch("text")
  private searchService(newValue: any) {
    if (newValue.length >= 3) {
      this.filterFnServices(newValue);
    } else {
      this.servicesOptions = this.serviciosCat;
    }
  }

  seleccionCategoria(id_categoria: any) {
    const category_index = this.categorias.findIndex(
      (cat: any) => cat.id == id_categoria
    );
    if (category_index >= 0) {
      this.categorias[category_index].seleccionado = true;
      this.expanded = false;
      this.tab = "";
      this.dataBuscar.categorias.push(id_categoria);
    }
    this.getOfertas();
  }

  deseleccionarCategoria(id_categoria: any) {
    const category_index = this.categorias.findIndex(
      (cat: any) => cat.id == id_categoria
    );
    if (category_index >= 0)
      this.categorias[category_index].seleccionado = false;

    this.dataBuscar.categorias = this.dataBuscar.categorias.filter(
      (cat: any) => cat != id_categoria
    );
    this.expanded = false;
    this.tab = "";
    this.getOfertas();
  }

  seleccionFecha(id_fecha: any) {
    for (let i = 0; i < this.fechas.length; i++) {
      if (this.fechas[i].id == id_fecha) {
        this.fechas[i].seleccionado = true;
        this.dataBuscar.fechas = id_fecha;
        this.tab = "";
      } else {
        this.fechas[i].seleccionado = false;
      }
    }
    this.getOfertas();
  }

  deseleccionarFecha(id_fecha: any) {
    for (let i = 0; i < this.fechas.length; i++) {
      if (this.fechas[i].id == id_fecha) {
        this.fechas[i].seleccionado = false;
        this.dataBuscar.fechas = 0;
        this.tab = "";
      }
    }

    this.getOfertas();
  }

  handleTab() {
    if (this.tab !== this.last_tab) this.last_tab = this.tab;
    else {
      this.tab = "";
      this.last_tab = "";
    }
  }

  private mostrarDatosDesktop(id_ofertas: any) {
    const oOferta = this.ofertas.find((oferta: any) => oferta.id == id_ofertas);

    this.ofertasData = oOferta;
  }

  private mostrarDatosMobile(id_ofertas: any) {
    this.mostrarDatosDesktop(id_ofertas);
    this.dataDialog = true;
  }

  private beforeCreate() {
    this.$q.loading.show();
    this.url = process.env.VUE_APP_AWS_ASSETS;
  }

  private getOfertas() {
    this.$q.loading.show();
    this.loading_offers = true;
    this.$axios
      .get("ofertas/pub", {
        params: {
          ...(this.dataBuscar.search &&
            this.dataBuscar.search != "" && { nombre: this.dataBuscar.search }),
          ...(this.dataBuscar.categorias &&
            this.dataBuscar.categorias.length > 0 && {
              categorias: this.dataBuscar.categorias
            }),
          ...(this.dataBuscar.fechas && { fecha: this.dataBuscar.fechas })
        }
      })
      .then((res: any) => {
        this.ofertas = res.data || [];
        this.mostrarDatosDesktop(this.ofertas[0].id);
        //this.dataBuscar.search = "";
        if (this.$route.query.cod && this.$route.query.cod != "") {
          var oOferta = this.ofertas.find(
            (oferta: any) => oferta.id == this.$route.query.cod
          );
          if (this.$q.screen.xs || this.$q.screen.sm) {
            this.mostrarDatosMobile(oOferta.id);
          } else {
            this.mostrarDatosDesktop(oOferta.id);
          }
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        this.$q.loading.hide();
        this.loading_offers = false;
      });
  }

  mounted() {
    this.$gtag.event("Seleccionar Servicio", {
      event_label: "Seleccionar servicio",
      event_category: "test",
      value: 1
    });
    window.scroll(0, 0);
    this.getOfertas();
    this.getCategorias();
  }

  private getCategorias() {
    this.$axios
      .get("oferta-categorias/listar/front", {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(res => {
        var responseData = res.data || [];
        this.categorias = responseData.map((cat: any) => ({
          ...cat,
          seleccionado: false
        }));
      })
      .catch((err: any) => {})
      .finally(() => {
        this.loading_categories = false;
      });
  }

  mostrarCurriculum() {
    if (!this.$store.state.logged) {
      this.$router.push({
        name: "login",
        query: {
          redirect: "newemployment"
        }
      });
    }
    this.upload_cv_dialog = true;
  }

  uploadCvMobile() {
    if (!this.$store.state.logged) {
      this.$router.push({
        name: "login",
        query: {
          redirect: "newemployment"
        }
      });
    }
    this.dataDialog = false;
    this.upload_cv_dialog = true;
  }

  postular() {
    this.$axios
      .post(
        "postulacions/crear",
        {
          id_oferta: this.ofertasData.id
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          }
        }
      )
      .then(response => {
        //this.$router.push({ name: "newrequest", replace: true });
        this.$emit("endPostulation", response.data);
        this.$q.notify({
          message: "Postulación registrada correctamente",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .catch(error => {
        if (error.response.data.error.statusCode == 401) {
          this.$q.notify({
            message: "Para postular debes iniciar sesión",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        } else {
          switch (error.response.data.error.message) {
            case "no_curriculum_error":
              this.$q.notify({
                message:
                  "Para postular debes registrar tu currículum en tus datos personales",
                color: "principal",
                position: "top",
                timeout: 5000
              });
              break;
            case "postulacion_data":
              this.$q.notify({
                message: "Ya tiene una postulación para esta oferta",
                color: "principal",
                position: "top",
                timeout: 5000
              });
              break;
            default:
              this.$q.notify({
                message:
                  "Hubo un problema creando la postulando, Inténtelo de nuevo más tarde",
                color: "principal",
                position: "top",
                timeout: 5000
              });
          }
        }
      });
  }

  private categoriaDialog(id: any, name: any) {
    this.catName = name;
    this.servicio = true;
    for (let i = 0; i < this.servicios.length; i++) {
      if (this.servicios[i].id_categoria == id) {
        this.serviciosCat.push(this.servicios[i]);
      }
    }
    this.servicesOptions = this.serviciosCat;
  }

  private filterFnServices(val: any) {
    const value = val.toLowerCase();
    this.servicesOptions = this.serviciosCat.filter((service: any) => {
      let filtered =
        service.nombre.toLowerCase().indexOf(value) > -1 ||
        service.descripcion.toLowerCase().indexOf(value) > -1;

      return filtered;
    });
  }

  private cerrarDialog() {
    this.servicio = false;
    this.serviciosCat = [];
    this.servicio_tag = [];
    this.aServicesCat = [];
    this.catService.value = null;
    this.catService.label = "";
    this.catName = "";
    this.service_selected = "";
  }
  private vaciarInput(e: any) {
    if (e == false) {
      this.service_selected = "";
    }
  }

  private async scrollToCategory(category_id: any) {
    let expansion_item = "expansion-" + category_id;
    let options = {
      behavior: "smooth",
      alignToTop: true
    };
    //sorry
    await new Promise(resolve => setTimeout(resolve, 375));
    this.$nextTick(() => {
      document.getElementById(expansion_item)!.scrollIntoView(options as any);
    });
  }

  private boldCoincidence(str: string, query: string): string {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    return (
      str.substr(0, x) +
      "<b>" +
      str.substr(x, q.length) +
      "</b>" +
      str.substr(x + q.length)
    );
  }
}
