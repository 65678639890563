
import CreatePostulationOmil from "@/components/empleos/forms/CreatePostulationOmil.vue";
import FormCard from "@/components/FormCard.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    FormCard,
    CreatePostulationOmil
  }
})
export default class NewPostulationOmil extends Vue {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private service: any;
  private mounted() {
    window.scroll(0, 0);
  }
}
