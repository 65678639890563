
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import FormCard from "@/components/FormCard.vue";
import RequestDetailForm from "@/components/forms/RequestDetailForm.vue";
import LogoSingle from "@/components/global/LogoSingle.vue";
// @ts-ignore-start
import colores from "@/scss/colores.scss";
// @ts-ignore-end

interface requestData {
  document_type: string;
  document_id: string;
  ticket_number: string;
}

@Component({
  components: {
    FormCard,
    RequestDetailForm,
    LogoSingle,
    colores
  }
})
export default class MyPostulationDetails extends Mixins(FormValidator) {
  protected formData: requestData = {
    document_type: "",
    document_id: "",
    ticket_number: ""
  };

  private postulacion = {
    id: "",
    oferta: "",
    empresa: "",
    razon_social: "",
    codigo: "",
    description: "",
    current_status: "",
    date: "",
    history: [],
    direccion: ""
  };

  private user = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    rut: "",
    email: "",
    document_type: ""
  };

  private ruta = "";
  private reserva: any = [];
  private ocultar: Boolean = false;
  private cancel_ticket: Boolean = false;
  private tipoReserva: Boolean = false;
  private count_retrieved: any = false;
  private historialPostulacion: any = 0;

  protected downloading_file = false;
  protected download_progress = 0;

  private mounted() {
    this.getPostulacion();
    if (this.$router.currentRoute.name == "postulationdetails") {
      this.ruta = "Detalle de la postulación";
    }
  }

  private getPostulacion() {
    this.$q.loading.show();

    this.$axios
      .get("postulacions/" + this.$route.params.id_postulacion, {
        params: {
          filter: {
            include: [
              {
                relation: "oferta",
                scope: {
                  include: [
                    {
                      relation: "empresa",
                      scope: {
                        fields: [
                          "id",
                          "nombre_fantasia",
                          "calle",
                          "numero",
                          "aclaratoria",
                          "razon_social"
                        ]
                      }
                    }
                  ],
                  fields: [
                    "id",
                    "id_empresa",
                    "nombre",
                    "descripcion",
                    "codigo"
                  ]
                }
              },
              {
                relation: "postulacion_estado",
                scope: {
                  fields: ["id", "nombre", "color"]
                }
              },
              {
                relation: "postulacion_historial",
                scope: {
                  where: {
                    estado: 1,
                    tipo: 2
                  },
                  include: [
                    {
                      relation: "postulacion_estado"
                    }
                  ],
                  order: ["fecha DESC"]
                }
              }
            ]
          }
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.$q.loading.hide();

        this.setPostulacionData(response.data);
        //this.setUserData(response.data.persona);
        this.ocultar = true;
        this.countHistorial();
      })
      .catch(error => {
        this.$q.loading.hide();
        if (error.response.data.error.message == "ticket_no_data") {
          this.$q.notify({
            message: "No se ha encontrado el ticket.",
            color: "principal",
            position: "top",
            timeout: 5000
          });

          this.$router.push({ name: "consultar" });
        } else {
          this.$router.push({ name: "consultar" });
        }
      });
  }

  private countHistorial() {
    this.$axios
      .get("postulacion-historials/count", {
        params: {
          where: {
            and: [
              { id_postulacion: Number(this.postulacion.id) },
              { estado: 1 }
            ]
          }
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.historialPostulacion = response.data.count;
        this.count_retrieved = true;
      })
      .catch(error => {
        this.$q.notify({
          message: "No se ha encontrado el ticket.",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private setUserData(user: any) {
    this.user.name = user.nombre;
    this.user.father_last_name = user.apellido_paterno;
    this.user.mother_last_name = user.apellido_materno;
    this.user.rut = user.rut;
    this.user.email = user.email;
    this.user.document_type = user.tipo_documento.toString();
  }

  private setPostulacionData(postulacion: any) {
    (this.postulacion.direccion = postulacion.oferta.direccion
      ? postulacion.oferta.direccion
      : "No hay dirección asociada"),
      (this.postulacion.id = postulacion.id);
    this.postulacion.razon_social = postulacion.oferta.empresa.razon_social;
    this.postulacion.codigo = postulacion.oferta.codigo;
    this.postulacion.description = postulacion.oferta.descripcion;
    this.postulacion.current_status = postulacion.postulacion_estado;
    this.postulacion.date = postulacion.fecha;
    this.postulacion.empresa = postulacion.oferta.empresa.nombre_fantasia;
    this.postulacion.oferta = postulacion.oferta.nombre
      ? postulacion.oferta.nombre
      : "No tiene nombre";
    /*if (postulacion.ticket_adjunto) {
      for (let i = 0; i < postulacion.ticket_adjunto.length; i++) {
        let file = {
          id: postulacion.ticket_adjunto[i].id,
          original: postulacion.ticket_adjunto[i].original
        };
        (this.postulacion.files as any).push(file);
      }
    }*/
    if (postulacion.postulacion_historial) {
      for (let i = 0; i < postulacion.postulacion_historial.length; i++) {
        if (postulacion.postulacion_historial[i]) {
          let history = {
            status: postulacion.postulacion_historial[i].estado_postulacion,
            status_name:
              postulacion.postulacion_historial[i].postulacion_estado.nombre,
            message: postulacion.postulacion_historial[i].mensaje,
            date: postulacion.postulacion_historial[i].fecha,
            color: postulacion.postulacion_historial[i].postulacion_estado.color
          };
          (this.postulacion.history as any).push(history);
        }
      }
    }
    /*if (postulacion.reserva) {
      this.reserva = postulacion.reserva;
      this.tipoReserva = true;
    } else {
      this.reserva = [];
      this.tipoReserva = false;
    }*/
  }

  /*private extension(filename: any) {
    var extension = filename.split(".").pop();
    return extension;
  }

  private name(filename: any) {
    let name = filename.substr(0, filename.lastIndexOf(".")) || filename;
    name = name.replaceAll("_", " ");
    return name;
  }

  protected updateProgressFromChild(value: number) {
    this.download_progress = value;
  }

  protected get progressLabel() {
    return Math.round(this.download_progress * 100).toString() + "%";
  }

  private download(id: number, nombre: string) {
    if (this.downloading_file) {
      this.$q.notify({
        message: "Solo puede descargar un archivo a la vez",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return 0;
    }
    this.downloading_file = true;
    this.$q.notify({
      message: this.$t("notification.download_start") as string,
      color: "principal",
      position: "top",
      timeout: 5000
    });
    this.$axios
      .get("ticket-adjuntos/bajar/" + id.toString(), {
        params: {
          tipo_documento: Number(this.user.document_type),
          rut: this.user.rut
        },
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          this.download_progress =
            Math.round((progressEvent.loaded * 100) / progressEvent.total) /
            100;
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.download_error") as string,
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }*/

  private downloadHistory(id: number, nombre: string) {
    if (this.downloading_file) {
      this.$q.notify({
        message: "Solo puede descargar un archivo a la vez",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return 0;
    }
    this.downloading_file = true;
    this.$q.notify({
      message: this.$t("notification.download_start") as string,
      color: "principal",
      position: "top",
      timeout: 5000
    });
    this.$axios
      .get("ticket-historial-adjuntos/front/bajar/" + id.toString(), {
        params: {
          tipo_documento: Number(this.user.document_type),
          rut: this.user.rut
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        },
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          this.download_progress =
            Math.round((progressEvent.loaded * 100) / progressEvent.total) /
            100;
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.download_error") as string,
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private getTextColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  getBtnColor(history: any) {
    let color = "";
    return "#" + history.color;
    /*switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      case 13:
        color = colores.color_estado_diez;
        break;
      case 15:
        color = colores.color_primario_dos;
        break;
      default:
        color = "";
        break;
    }
    return color;*/
  }

  private getStateColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      case 15:
        color = colores.color_primario_dos;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }

  private humanTime(date: any) {
    let date_object = new Date(date);
    let date_time = date_object
      .toLocaleString("es-CL", { timeZone: "UTC" })
      .slice(0, -3);
    let newTime = date_time.split(" ")[1].split(":");

    return newTime[0] + ":" + newTime[1];
  }

  private humanDateTime(date: any) {
    let date_object = new Date(date);
    return date_object
      .toLocaleString("es-CL", { timeZone: "UTC" })
      .slice(0, -3);
  }

  private formatRutShow(rut: any) {
    if (rut.replace("-", "").replace(/[.-]/g, "").length <= 7) {
      return rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
    } else {
      return rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{0,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
  }

  private cancelarTicket(idTicket: any) {
    this.$q.loading.show();
    this.$axios
      .delete("ticket/cancelar", {
        params: {
          id: idTicket,
          tipo: this.tipoReserva
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.$q.loading.hide();
        this.$router.go(0);
        this.$q.notify({
          message: "Se ha canceló la solicitud.",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .catch(error => {
        this.$q.loading.hide();
        this.$q.notify({
          message: "Ha ocurrido un error.",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }
}
