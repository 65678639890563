
import { Component, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import StepTracker from "@/components/request/StepTracker.vue";
import SearchEmployment from "@/components/empleos/SearchEmployment.vue";
import EndPostulation from "@/components/empleos/EndPostulation.vue";
import DialogService from "@/components/request/DialogService.vue";

@Component({
  components: {
    LogoSingle,
    StepTracker,
    SearchEmployment,
    EndPostulation,
    DialogService,
    IdentifyUser: () => import("@/components/request/IdentifyUser.vue"),
    SendRequest: () => import("@/components/request/SendRequest.vue"),
    EndRequest: () => import("@/components/request/EndRequest.vue")
  }
})
export default class NewRequest extends Vue {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private service: any;
  private postulation_end = false;
  private postulacion: any;

  private mounted() {
    this.$store.dispatch("setStateToCero");
    this.$store.dispatch("cleanTicket");
    window.scroll(0, 0);
  }

  private selectService(e: any) {
    if (e) {
      this.service = e;
    }
  }

  private endPostulation(postulation: any) {
    this.postulacion = postulation;
    this.postulation_end = true;
  }
}
