var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"ticket"}},[_c('div',{staticClass:"row justify-center full-width q-pb-xl"},[_c('q-card',{staticClass:"col-xs-11 col-sm-10 col-md-5 col-lg-5 col-xl-5 full-height q-my-lg bg-principal rounded-borders"},[_c('div',{staticClass:"absolute-top row justify-end",staticStyle:{"z-index":"1"}},[_c('q-btn',{staticClass:"q-mt-md q-mr-md cursor-pointer",staticStyle:{"color":"white"},attrs:{"icon":"close","round":"","flat":"","color":"white","dense":"","size":"small"},on:{"click":function($event){return _vm.routerGo('newemployment')}}})],1),_c('div',{staticClass:"row flex-center"},[_c('div',{staticClass:"col-11 flex-center row q-pt-lg"},[_c('div',{staticClass:"col-5 items-center justify-end row"},[_c('q-icon',{staticClass:"icono_titulo_resumen",attrs:{"name":"check_circle_outline","color":"white"}})],1),_c('div',{staticClass:"col-7 items-center justify-start row"},[_c('div',{staticClass:"col-12 row justify-start"},[_c('label',{staticClass:"titulo_resumen"},[_vm._v("Postulación creada")])])])]),_c('div',{staticClass:"col-xs-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 row justify-center items-center"},[_c('q-card',{staticClass:"login-card col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 rounded-borders",staticStyle:{"margin-top":"15px !important","margin-bottom":"50px !important"}},[_c('q-toolbar',{staticStyle:{"background-color":"#E5E5E5"}},[_c('div',{staticClass:"row items-center justify-center full-width"},[_c('div',{staticClass:"margin-estado",class:'text-center titulo_card row items-center text-bold ' +
                      (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'q-ml-xs' : 'q-ml-lg')},[_vm._v(" Estado Actual de su Postulación: ")])]),_c('div',{staticClass:"row col-3"},[_c('q-card',{staticClass:"full-width btn-detalle"},[_c('q-card-section',{staticClass:"q-pa-sm",style:({
                      'background-color': '#' + _vm.estado.color
                    })},[_c('label',{staticClass:"row justify-center text-white",staticStyle:{"font-family":"'Font-principal Medium'"}},[_vm._v(" "+_vm._s(_vm.estado.nombre)+" ")])])],1)],1)]),_c('q-card-section',[_c('div',{staticClass:"row flex-center full-width"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-12 row flex-center"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Oferta:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(_vm._s(_vm.oferta))])]),_c('div',{staticClass:"col-12 q-py-md"},[_c('q-separator')],1),_c('div',{staticClass:"col-12 row items-center justify-between"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row justify-center q-py-sm"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Postulante:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(_vm._s(_vm.persona.nombre + " " + _vm.persona.apellido_paterno + " " + _vm.persona.apellido_materno))])])]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row justify-start q-py-sm"},[_c('q-separator',{staticClass:"gt-sm",attrs:{"vertical":"","inset":""}}),_c('div',{class:'col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-start' +
                            (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : ' q-pl-md')},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Fecha:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(_vm._s(_vm.humanDate(_vm.fecha)))])])])],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row justify-start q-py-sm"},[_c('q-separator',{staticClass:"gt-sm",attrs:{"vertical":"","inset":""}}),_c('div',{class:'col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-start' +
                            (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : ' q-pl-md')},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Hora:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(_vm._s(_vm.humanTime(_vm.fecha)))])])])],1)]),_c('div',{staticClass:"col-12 q-py-md"},[_c('q-separator')],1),_c('div',{staticClass:"col-12 row flex-center"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Descripción de la oferta:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.oferta_descripcion)}})])])]),_c('div',{staticClass:"col-12 q-py-md"},[_c('q-separator')],1),_c('div',{staticClass:"col-12 row items-center justify-between"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row justify-center q-py-sm"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Dirección:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(_vm._s(_vm.direccion ? _vm.direccion : "Sin Dirección"))])])]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row justify-start q-py-sm"},[_c('q-separator',{staticClass:"gt-sm",attrs:{"vertical":"","inset":""}}),_c('div',{class:'col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-start' +
                            (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : ' q-pl-md')},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Código:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(_vm._s(_vm.oferta_codigo))])])])],1)]),_c('div',{staticClass:"col-12 row flex-center q-pb-md q-pt-lg"},[_c('div',{staticClass:"col-12 row flex-center"},[_c('q-btn',{staticClass:"full-width",staticStyle:{"border-radius":"7px"},attrs:{"label":_vm.$t('forms.go_home'),"color":"principal"},on:{"click":function($event){return _vm.routerGo('newemployment')}}})],1)])])])])],1)],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }