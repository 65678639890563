
import { Vue, Prop, Component, Mixins } from "vue-property-decorator";
// @ts-ignore-start
import colores from "@/scss/colores.scss";
// @ts-ignore-end
import LabelHelpers from "@/mixins/LabelHelpers";

@Component({
  components: {
    colores
  }
})
export default class MyPostulationsList extends Mixins(LabelHelpers) {
  private postulations = [];
  finish_loading: any = null;

  private hello: Boolean = false;

  private created() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("postulacions", {
        params: {
          filter: {
            where: {
              id_persona: Number(this.$store.state.user.user_id),
              estado: 1
            },
            order: ["fecha desc"],
            include: [
              {
                relation: "oferta",
                scope: {
                  include: [
                    {
                      relation: "empresa",
                      scope: {
                        fields: ["id", "nombre"]
                      }
                    }
                  ],
                  fields: [
                    "id",
                    "nombre",
                    "id_empresa",
                    "descripcion",
                    "codigo"
                  ]
                }
              },
              {
                relation: "postulacion_historial",
                scope: {
                  where: {
                    estado: 1,
                    tipo: 2
                  },
                  order: ["fecha DESC"],
                  limit: 1,
                  include: [
                    {
                      relation: "postulacion_estado"
                    }
                  ]
                }
              },
              "postulacion_estado"
            ],
            fields: [
              "id",
              "id_persona",
              "id_oferta",
              "estado_postulacion",
              "estado",
              "fecha"
            ]
          }
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.postulations = response.data as any;

        if (this.postulations.length > 0) {
          this.finish_loading = true;
          this.$emit("visible", {
            visible: this.finish_loading
          });
        } else {
          this.finish_loading = false;
          this.$emit("visible", {
            visible: this.finish_loading
          });
        }
      })
      .catch(error => {
        this.finish_loading = false;
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private getTextColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }
}
