
import { Component, Vue } from "vue-property-decorator";
import MyRequestsTable from "@/components/request/MyRequestsTable.vue";
import MyPostulationsList from "@/components/empleos/MyPostulationsList.vue";
import countries from "@/helpers/countries.json";

@Component({
  components: {
    MyRequestsTable,
    MyPostulationsList
  }
})
export default class MyPostulations extends Vue {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private ruta = "";
  private visibleButton: boolean = false;

  private mounted() {
    if (this.$router.currentRoute.name == "mypostulations")
      this.ruta = "Mis Postulaciones";
  }
  private get nationality(): string {
    let nation = countries.find(
      country => country.value === this.$store.state.user.country
    );
    return nation!.label;
  }
  private get rut(): string {
    if (this.$store.state.user.document_type == 1) {
      let id = this.$store.state.user.rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
      return id;
    } else {
      return this.$store.state.user.rut;
    }
  }

  private visible(data: any) {
    this.visibleButton = data.visible;
  }
}
